<template>
  <product-step-wrapper
    :questions="questions"
    :default-model="defaultModel"
    :submit-function="saveData"
    :on-success="onSubmit"
    skip-intro
  />
</template>

<script>
import ProductStepWrapper from "./ProductStepWrapper";
import { questionByType } from "../../../utils/service/Questionnaire";
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "GeneralQuestions",
  components: { ProductStepWrapper },
  props: {
    value: {},
    saveData: {
      type: Function,
      required: true,
    },
    onSubmit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      questions: questionByType(this.$route.params.type),
      defaultModel: {},
    };
  },
  async beforeCreate() {
    this.$store.commit("questionnaire/RESET_CURRENT_QUESTION");
  },
  created() {
    this.defaultModel = this.value;

    if (
      this.questions?.find(({ inputs }) =>
        inputs?.find(({ model }) => model === "company_email")
      )
    ) {
      const clientEmail = !this.clientEmail.match(/@termshub\.io/)
        ? this.clientEmail
        : "";

      this.defaultModel.company_email =
        this.defaultModel.company_email || clientEmail;
    }

    if (this.businessProgressByType(this.$route.params.type)) {
      this.questions.forEach((q, qIndex) => {
        this.SET_CURRENT_QUESTION_STATUS({
          q: qIndex + 1,
          visited: true,
        });
      });
    }
  },
  computed: {
    ...mapGetters("business", ["businessProgressByType"]),
    ...mapState({
      clientEmail: (state) => state.client.email,
    }),
  },
  methods: {
    ...mapMutations("questionnaire", ["SET_CURRENT_QUESTION_STATUS"]),
  },
};
</script>

<style lang="scss" scoped></style>
